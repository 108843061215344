













































import {Component, Vue, Watch} from 'vue-property-decorator';
import HelpFaqNav from '@/pages/Help/Help.faq.nav.vue';
import ExpansionPanel from '@/components/Ui/ExpansionPanel.vue';
import Backlink from '@/components/Backlink.vue';

@Component({
  components: {
    HelpFaqNav,
    ExpansionPanel,
    Backlink
  }
})
export default class HelpFaqTab extends Vue {
  private tabNum: number = 0;
  private isNavActive: boolean = true;

  @Watch('$store.getters.isMobileAdaptiveMode')
  private changeNavActive() {
    this.isNavActive = true;
  }

  private get getNavList() {
    return [
      this.$t('help.topics.items.0.title'),
      this.$t('help.topics.items.1.title'),
      this.$t('help.topics.items.2.title')
    ];
  }

  private get getItemsList() {
    return this.$t(`help.topics.items.${this.tabNum}.items`);
  }

  private changeTab() {
    if (this.$store.getters.isMobileAdaptiveMode) {
      this.isNavActive = !this.isNavActive;
    }
  }
}
