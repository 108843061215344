


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ExpansionPanel extends Vue {
  @Prop({type: Array, required: true})
  private items!: {title: string; desc: string; }[];
}
