


























import {Component, Vue} from 'vue-property-decorator';
import ContactUsImg from '@/assets/icons/help/contact-us-img.svg';
import {IHelpData} from '@/services/api/user.service';
import {helpSourceEnum} from '@/interfaces';

const defaultHelpData = (): IHelpData => {
  return {
    message: '',
    source: helpSourceEnum.TENANT_APP
  };
};
@Component({
  components: {ContactUsImg}
})
export default class HelpContactUsTab extends Vue {
  private helpData: IHelpData = defaultHelpData();

  private sendHelp() {
    if (!this.helpData.message?.length) {
      return;
    }

    this.$store.dispatch('sendHelp', this.helpData)
      .then(() => {
        this.helpData = defaultHelpData();
        this.$notify({
          group: 'notification',
          type: 'notification-success',
          text: this.$tc('help.contactUs.success'),
        });
      })
      .catch((error) => {
        this.$notify({
          group: 'notification',
          type: 'notification-error',
          text: error.response.data.message,
        });
      });
  }
}
