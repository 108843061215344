























import {Component, Vue} from 'vue-property-decorator';
import HelpFaqTab from '@/pages/Help/Help.faq.tab.vue';
import HelpContactUsTab from '@/pages/Help/Help.contactUs.tab.vue';
import {i18n} from '@/main';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.help.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.help.description')
      }]
    };
  },
  components: {HelpFaqTab, HelpContactUsTab}
})
export default class HelpPage extends Vue {
  private currentTab: number = 0;

  public created() {
    if (this.$route.query.tab) {
      this.currentTab = Number(this.$route.query.tab);
    }
  }
}
