


















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class HelpFaqNav extends Vue {
  private currentTab: number = 0;

  @Prop({type: Array, required: true})
  private navList!: string[];
}
